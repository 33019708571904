<template>
  <div class="pay-page">
    <h2 class="pay-title">付费内容</h2>
    <div class="pay-content">
      <Card title="注册会员" style="width: 300px">
        <p>免费体验7天</p>
        <p>存储空间100M</p>
        <p>最多创建10个项目</p>
      </Card>
      <Card title="包月会员" style="width: 300px">
        <p>30天</p>
        <p>创建项目无限制</p>
        <p>存储空间1G</p>
        <p>促销价<span class="price">19.9元</span></p>
        <p><span>原价</span><span class="slash">99.9</span></p>
        <button @click="gotoPay">支付19.9购买</button>
      </Card>
      <Card title="季度会员" style="width: 300px">
        <p>90天</p>
        <p>创建项目无限制</p>
        <p>存储空间2G</p>
        <p>促销价<span class="price">49.9元</span></p>
        <p><span>原价</span><span class="slash">299.9</span></p>
        <button @click="gotoPay">支付49.9购买</button>
      </Card>
      <Card title="包年会员" style="width: 300px">
        <p>365天</p>
        <p>创建项目无限制</p>
        <p>存储空间4G</p>
        <p>促销价<span class="price">199.9元</span></p>
        <p><span>原价</span><span class="slash">999.9</span></p>
        <button @click="gotoPay">支付199.9购买</button>
      </Card>
      <Card title="终身会员" style="width: 300px">
        <p>永久有效</p>
        <p>创建项目无限制</p>
        <p>存储空间100G</p>
        <p>促销价<span class="price">1999.9元</span></p>
        <p><span>原价</span><span class="slash">4999.9</span></p>
        <button @click="gotoPay">支付1999.9购买</button>
      </Card>
    </div>>
  </div>
  </template>
  <script lang="ts">
  import { Card } from 'ant-design-vue'
import { defineComponent, } from 'vue'
  export default defineComponent({
    components: { Card },
    methods: {
      gotoPay () {
        this.$router.push('/payAli')
      }
    }
})
  </script>
  <style>
  .pay-page {
    height: 80vh;
    background-color: #fcfcfc;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .pay-title {
    color: #000;
    text-align: center;
    padding-top: 20px;
  }
  .pay-content {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
  }
  .slash {
    text-decoration: line-through;
  }
  .pay-qcode {
    width: 50%;
    margin: auto;
    text-align: center;
    display: flex;
  }
  .price {
    color: red;
    font-size: 20px;
  }
  </style>
  