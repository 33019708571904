
import { defineComponent, PropType, computed, ref, watch } from 'vue'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { TemplateProps } from '../store/templates'
import { Modal } from 'ant-design-vue'
import { gotoEditor } from '@/utils/utils'
export default defineComponent({
  name: 'works-list',
  emits: ['on-copy', 'on-delete'],
  components: {
    EditOutlined,
    DeleteOutlined
  },
  props: {
    list: {
      type: Array as PropType<TemplateProps[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    transferStatus: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {


    const deleteClicked = (id: string) => {
      Modal.confirm({
        title: '确定要删除该作品吗？',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          context.emit('on-delete', id)
        }
      })
    }
    const copyClicked = (id: number) => {
      context.emit('on-copy', id)
    }
    const editWork = (id: string) => {
      gotoEditor(id)
    }
    return {
      deleteClicked,
      copyClicked,
      editWork
    }
  }
})
