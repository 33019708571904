<template>
  <div v-if="!user.isLogin">
  <router-link to="/login" >
    <a-button 
      type="primary"  
      class="user-profile-component user-login"
    >
      登录
    </a-button>
  </router-link>
  <router-link to="/signUp">
    <a-button 
      type="primary"  
      class="user-profile-component user-signup"
    >
      注册
    </a-button>
  </router-link>
  </div>

  <div v-else>
    <div  class="user-profile-component">
      <span class="user-nickname">{{user.data.nickname}}</span>
      <a-button type="primary" class="btn" @click="createDesign">创建作品</a-button>
      <a-button type="primary" class="btn"><router-link to="/works" >我的作品</router-link></a-button>
      <a-button type="primary" class="btn" @click="logout">登出</a-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { UserProps } from '../store/user'
import { gotoEditor } from '../utils/utils'
export default defineComponent({
  name: 'user-profile',
  props: {
    user: {
      type: Object as PropType<UserProps>,
      required: true
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const createDesign = async () => {
      axios.post('/work/new' ).then((res) => {
        if (res.data && res.data.data) {
          const workId = res.data.data.uuid
          gotoEditor(workId)
        }
        else {
          message.error('创建作品失败')
        }
      }).catch((err) => {
        console.log('err', err)
      })
    }
    const logout = () => {
      store.commit('logout')
      message.success('退出登录成功，2秒后跳转到首页', 2)
      setTimeout(() => {
        router.push('/')
      }, 2000)
    }
    return {
      logout,
      createDesign
    }
  }
})
</script>
<style>
.user-profile-component .btn {
  margin-left: 10px;
}
.user-profile-dropdown {
  border-radius: 2px !important;
}
.user-operation > * {
  margin-left: 30px !important;
}
.user-login {
  margin-right: 10px;
}
.user-nickname {
  margin-right: 10px;
  color:white;
}
</style>
