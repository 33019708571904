
  import { Card } from 'ant-design-vue'
import { defineComponent, } from 'vue'
  export default defineComponent({
    components: { Card },
    methods: {
      gotoPay () {
        this.$router.push('/payAli')
      }
    }
})
  