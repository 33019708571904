<template>
  <div class="content-container">
    <img style="visibility: visible; width: 100%; height: auto;" src="../assets/animation/banner.png" />
    <section>
      <div class="videoSec">
        <div class="bgPattern"></div>
        <div class="videoCon">
          <div class="tableDisplay">
            <div class="vCenter">
              <h1>龙骨动画解决方案</h1>
              <div class="des">
                <p>全球最早的2D骨骼动画解决方案</p>
                <p>打通游戏动画设计和开发工作流</p>
                <p>更少的美术成本 更生动的动画效果</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="one">
      <div class="container">
        <div class="oneimg1 col-md-6" style="text-align: -webkit-center;">
          <img src="../assets/animation/2.png" />
        </div>
        <div class="col-md-6">
          <div class="content">
            <h3>轻松动态换装</h3>
            <div class="des">
              <p>动画角色中的任何图片,</p>
              <p>都是可以通过一行代码在运行时替换的，</p>
              <p>轻松实现动态换装的功能。</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <img src="../assets/animation/2.png" />
        </div>
      </div>
    </section>

    <section class="two">
      <div class="container">
        <div class="col-md-6">
          <img src="../assets/animation/1.png" />
        </div>
        <div class="col-md-6">
          <div class="content">
            <h3>美术需求更少</h3>
            <div class="des">
              <p>只保存各部分切图组成的纹理集和动画数据，</p>
              <p>只需要极少的原画，便可完成千变万化的动作动画组合。</p>
              <p>在动画制作时，只需要完成对关键帧的编辑，通过动画补间，</p>
              <p>便可自动生成流畅的动画动作。</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import { useStore } from 'vuex'
import { GlobalDataProps } from '../store/index'
// import TemplateList from '../components/TemplateList.vue'
export default defineComponent({
})
</script>

<style>
.page-title {
  color: #fff;
}
.col-md-6 {
  float: left;
}
.container {
  width: 970px;
}
.content-container {
  background: #fff;
  min-height: 85vh;
  max-width: 1200px;
  margin: auto auto;
  width: 100%;
  img {
    padding: 0;
  }
}
.videoSec video {
	margin: -2px 0;
}

.videoSec .videoCon {
	position: absolute;
	top: 60px;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
}
.vCenter {
  text-align: center;
  padding-left: 0px;
  padding-top: 80px;
}

.videoSec h1 {
  color: #151515;
  margin-top: 90px;
  font-size: 50px;
}

.videoSec .des {
  color: #949494;
}

.one {
  padding-top: 85px;
  padding-bottom: 85px;
  color: #737373;
}

.one .content {
  margin-left: 100px;
  margin-top: 150px;
}

.one .content p {
  margin-bottom: 5px;
}

.one .content h3 {
  margin-bottom: 30px;
  color: #000000;
}

.one .content .des {
  font-size: 16px;
}

.one .oneimg1 {
  display: none;
  text-align: center;
}

.two {
  padding-bottom: 85px;
  color: #737373;
}
.two img {
  width: 500px;
}
.two .content {
  margin-left: 10px;
  margin-top: 150px;
}

.two .content p {
  margin-bottom: 5px;
}

.two .content h3 {
  margin-bottom: 30px;
  color: #000000;
}

.two .content .des {

  font-size: 16px;
}

.three {
  background-color: #EEE;
}

.three .dbmoreItem {
  padding-bottom: 25px;
}

.three .texing1 {
  background-color: #FFFFFF;
  min-height: 420px;
  border: 1px #F3F3F3 solid;
  box-shadow: 0 0 8px rgba(141, 141, 140, .2);
  -webkit-box-shadow: 0 0 8px rgba(141, 141, 140, .2);
  -moz-box-shadow: 0 0 8px rgba(141, 141, 140, .2);
}

.three .texing1 img {
  position: relative;
  display: block;
  width: 100%;
}

.three .gongneng-name {
  font-size: 24px;
  font-weight: 500;
  padding-left: 10px;
  color: #000000;
}

.three .gongneng-span-name {
  padding: 10px 20px 20px 20px;
  max-width: 518px;
}

.three .span-name2 {
  color: #737373;
  font-size: 15px;
  line-height: 28px;
}

.three h2 {
  margin-top: 65px;
  margin-bottom: 55px;
  text-align: center;
  color: #000000;

}

.downDisplay {
  height: 100px;
  background-color: rgba(45, 45, 46, 0);
  margin-top: 0px;
  margin-bottom: 40px;
  text-align: center;
}

.downDisplay button {
  background-color: #00BBFE;
  border: 0;
  width: 200px;
  height: 50px;
  font-size: 18px;
}

.downDisplay button:hover {
  background-color: #07a9e2;
}

.bgPattern {
  background-color: rgba(1, 1, 1, 0);
}

@media only screen and (max-width: 767px) {
  .one {
    padding-bottom: 10px;
  }

  .one .content {
    margin-left: 0px;
    margin-top: 0px;
  }

  .one img {
    display: none;
  }

  .one p {
    font-size: 14px;
    margin-bottom: 1px;
  }

  .one .oneimg1 {
    display: block;

  }

  .one .oneimg1 img {
    display: block;
    width: 75%;
  }

  .two {
    padding-top: 10px;
  }

  .two .content {
    margin-left: 0px;
    margin-top: 0px;
  }

  .two img {
    width: 90%;
  }

  .two p {
    font-size: 14px;
    margin-bottom: 1px;
  }

  .videoSec h1 {
    font-size: 20px;
    margin-top: 60px;
  }

  .downDisplay button {
    width: 150px;
  }
  .dbmoreItem {
    padding-bottom: 25px;
  }
}
</style>