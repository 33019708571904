<template>
  <div class="homepage-container">
    <a-layout :style="{background: '#fff'}">
      <a-layout-header class="header">
        <div class="page-title">
          <router-link to="/" class="page-tab">龙骨主页</router-link>
          <router-link to="/pay" class="page-tab">付费资讯</router-link>
          <router-link to="/about" class="page-tab">关于我们</router-link>
          <router-link to="/contact" class="page-tab">联系我们</router-link>
        </div>
        <user-profile :user="user"></user-profile>
      </a-layout-header>
      <a-layout-content class="home-layout">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
    <a-layout-footer>
      <span>{{footer}}</span>
      <a href="http://beian.miit.gov.cn/">{{beian}}</a>
    </a-layout-footer>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { GlobalDataProps } from '../store/index'
import UserProfile from '../components/UserProfile.vue'
export default defineComponent({
  name: 'Index',
  components: {
    UserProfile
  },
  setup () {
    const store = useStore<GlobalDataProps>()
    const user = computed(() => store.state.user)
    const url = window.location.href
    let beian = ''
    let footer = '龙骨动画'
    if(url.indexOf('dragonbones.cn') > -1) {
      beian = '京公网安备11011402054398号  京ICP备2024092089号'
      footer = '龙骨动画'
    } else {
      beian = '京ICP备2024100042号'
      footer = '北京哪吒互娱科技有限公司 '
    }
    return {
      user,beian,footer
    }
  }
})
</script>

<style>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-title {
  color: #fff;
}
.page-tab {
  color: #fff;
  margin-right: 20px;
}
.ant-layout-footer {
  text-align: center;
}
</style>
