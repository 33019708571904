
import { defineComponent, PropType } from 'vue'
import axios from 'axios'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { UserProps } from '../store/user'
import { gotoEditor } from '../utils/utils'
export default defineComponent({
  name: 'user-profile',
  props: {
    user: {
      type: Object as PropType<UserProps>,
      required: true
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const createDesign = async () => {
      axios.post('/work/new' ).then((res) => {
        if (res.data && res.data.data) {
          const workId = res.data.data.uuid
          gotoEditor(workId)
        }
        else {
          message.error('创建作品失败')
        }
      }).catch((err) => {
        console.log('err', err)
      })
    }
    const logout = () => {
      store.commit('logout')
      message.success('退出登录成功，2秒后跳转到首页', 2)
      setTimeout(() => {
        router.push('/')
      }, 2000)
    }
    return {
      logout,
      createDesign
    }
  }
})
