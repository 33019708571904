
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { GlobalDataProps } from '../store/index'
import UserProfile from '../components/UserProfile.vue'
export default defineComponent({
  name: 'Index',
  components: {
    UserProfile
  },
  setup () {
    const store = useStore<GlobalDataProps>()
    const user = computed(() => store.state.user)
    const url = window.location.href
    let beian = ''
    let footer = '龙骨动画'
    if(url.indexOf('dragonbones.cn') > -1) {
      beian = '京公网安备11011402054398号  京ICP备2024092089号'
      footer = '龙骨动画'
    } else {
      beian = '京ICP备2024100042号'
      footer = '北京哪吒互娱科技有限公司 '
    }
    return {
      user,beian,footer
    }
  }
})
