<template>
  <div class = "about-page">
    <h2 class = "about-title">关于我们</h2>
    <div class = "about-text">北京哪吒互娱科技有限公司是一家在游戏和交互式2D动画领域提供先进解决方案的高科技企业。由前白鹭时代公司的龙骨动画团队成员原班人马打造。
    <div class = "about-text" >公司一直致力于移动互联网泛娱乐矩阵的发展，拥有成熟的工程师研发团队，并采用先进的管理模式。哪吒互娱以发展创新、共赢未来为企业的经营理念，通过技术创新和行业历练，为客户提供高质量的服务，赢得了国内外市场的认可。</div>
    <div class = "about-text" >公司的核心产品包括龙骨动画编辑器和播放器，其中编辑器支持一系列高级特性，如骨骼动画、IK（逆动力学）、Mesh网格、骨骼绑定以及FFD自由形变等，这些功能能够满足多种动画制作需求，显著提高动画制作的效率。播放器则支持多种主流游戏引擎，包括Egret、Cocos、PixiJS、Phaser和Unity，确保了动画内容能够在跨平台设备上流畅运行。</div>
    <div class = "about-text" >龙骨动画解决方案是游戏开发者、交互式动画创作者以及动画短片制作人的强大工具，它不仅提升了制作效率，还保证了动画在不同平台上的兼容性和表现力。</div>
    <div class = "about-text" >公司以弘扬中华文化为己任，用快乐的方式传承中国传统文化，致力于通过创新、服务、品质提升价值，打造中国自有文化品牌。</div>
</div>
  </div>
  </template>
  <script lang="ts">
  import { defineComponent, } from 'vue'
  export default defineComponent({
  })
  </script>
  <style>
  .about-page {
    height: 80vh;
    background-color: #fcfcfc;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .about-title {
    color: #000;
    text-align: center;
    padding-top: 20px;
  }
  .about-text {
    padding-left:  100px;
    padding-right: 100px;
    color: #000;
    text-align: center;
    padding-top: 20px;
  }
  </style>
  